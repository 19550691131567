import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import StripeCheckout from 'react-stripe-checkout';
import { Button } from 'semantic-ui-react';

import { CartContext } from './CartProvider';

const Checkout = () => {
  const { cart, count, total, setOrder } = useContext(CartContext);

  const onToken = async (token, addresses) => {
    const items = cart.map(([sku, quantity]) => ({
      type: 'sku',
      parent: sku.id,
      quantity,
    }));

    let response;
    try {
      response = await fetch('/.netlify/functions/orderCreate', {
        method: 'POST',
        body: JSON.stringify({
          token,
          order: {
            currency: 'usd',
            items,
            shipping: {
              name: addresses.shipping_name,
              address: {
                line1: addresses.shipping_address_line1,
                line2: addresses.shipping_address_line2 || '',
                city: addresses.shipping_address_city,
                state: addresses.shipping_address_state,
                postal_code: addresses.shipping_address_zip,
                country: addresses.shipping_address_country_code,
              },
            },
          },
        }),
      }).then(res => res.json());
    } catch (err) {
      alert(err.message);
    }

    const orderNumber = response.data && response.data.id;
    localStorage.setItem('cart', '{}');
    setOrder(orderNumber);
    navigate(`/order?id=${orderNumber}`);
  };

  return (
    <StripeCheckout
      token={onToken}
      stripeKey={process.env.STRIPE_PUBLISHABLE_KEY}
      name="gatsby-starter-stripe" // the pop-in header title
      description={`${count} Items`} // the pop-in header subtitle
      image={null} // the pop-in header image (default none)
      panelLabel="Pay" // prepended to the amount in the bottom pay button
      amount={total} // cents
      currency="USD"
      locale="en"
      shippingAddress
      billingAddress
      zipCode
      allowRememberMe
    >
      <Button primary>Checkout for ${total / 100}</Button>
    </StripeCheckout>
  );
};

export default Checkout;

import React from 'react';
import { Grid, Responsive, Segment, Message } from 'semantic-ui-react';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Cal from '../components/Calendar';
import Legend from '../components/Legend';

// maybe call this date selection?
const calendar = ({ eventsList = [{ start: '2019-08-02' }] }) => (
  <Layout>
    <SEO
      title="Schedule your Hunt"
      keywords={['duck', 'hunt', 'Georgia', 'schedule']}
    />
    <Responsive>
      <Segment placeholder style={{ padding: '4em 0em' }} vertical>
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Message
              icon="calendar alternate outline"
              header="Select a date for your hunt"
              content={Legend}
            />
            <Cal eventsList={eventsList} />
          </Grid.Row>
        </Grid>
      </Segment>
    </Responsive>
  </Layout>
);

export default calendar;

import React from 'react';
import { List } from 'semantic-ui-react';

const Legend = (
  <List>
    <List.Item>
      <List.Icon name="check" />
      <List.Content>
        Choose an available date. Dates that are unavailable have a grey
        background and a strike through the date.
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name="hand point right outline" />
      <List.Content>
        Click on the date and follow the instructions to submit your payment.
      </List.Content>
    </List.Item>
    <List.Item>
      <List.Icon name="lock" />
      <List.Content>
        Once we process your payment, we will send you an email confirming your
        stay.
      </List.Content>
    </List.Item>
  </List>
);

export default Legend;

import React, { Component } from 'react';
import { Calendar as Base } from 'react-calendar-component';
import moment from 'moment';

import CalendarDayCell from './CalendarDayCell';
import CheckoutModal from './CheckoutModal';

import './Calendar.css';

const checkDisabled = (eventDate, targetDay) =>
  moment(eventDate).isSame(targetDay) || moment(targetDay).isBefore(moment());

export default class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(),
      eventsList: props.eventsList || [],
      modalOpen: false,
      selectedDate: null,
    };
  }

  render() {
    const { eventsList, modalOpen, selectedDate } = this.state;

    return (
      <>
        <CheckoutModal open={modalOpen} selectedDate={selectedDate} />
        <Base
          onChangeMonth={date => this.setState({ date, modalOpen: false })}
          date={this.state.date}
          onPickDate={date =>
            this.setState({ modalOpen: true, selectedDate: date })
          }
          renderDay={props => (
            <CalendarDayCell
              {...props}
              disabled={eventsList
                .map(({ start }) => start)
                .some(event => checkDisabled(event, props.day))}
            />
          )}
          renderHeader={({ date, onPrevMonth, onNextMonth }) => (
            <div className="Calendar-header">
              <button onClick={onPrevMonth}>«</button>
              <div className="Calendar-header-currentDate">
                {date.format('MMMM YYYY')}
              </div>
              <button onClick={onNextMonth}>»</button>
            </div>
          )}
        />
      </>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const CalendarDayCell = ({
  day,
  classNames,
  onPickDate,
  selected,
  disabled,
}) => {
  const current = day.isSame(moment(), 'day')
    ? 'Calendar-grid-item--current-day'
    : '';
  const disabledCN = disabled ? 'Calendar-grid-item--disabled' : '';
  const selectedCN = selected ? 'Calendar-grid-item--selected' : '';

  return (
    <div
      key={day.format()}
      className={[
        classNames,
        'Calendar-grid-item',
        current,
        disabledCN,
        selectedCN,
      ].join(' ')}
      onClick={() => !disabled && onPickDate(day)}
    >
      {day.format('Do')}
      <br />
    </div>
  );
};

CalendarDayCell.propTypes = {
  classNames: PropTypes.string,
  day: PropTypes.instanceOf(moment).isRequired,
  onPickDate: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CalendarDayCell;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Modal } from 'semantic-ui-react';
import moment from 'moment';

import Checkout from './Checkout';

export default class CheckoutModal extends Component {
  handleClose = () => this.setState({ open: false });

  constructor(props) {
    super(props);

    this.state = { open: props.open };
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    const { open } = nextProps;
    if (open !== this.state.open) {
      this.setState({ open });
    }
  }

  render() {
    const { open } = this.state;
    const { selectedDate } = this.props;

    return (
      <Modal open={open} onClose={this.handleClose} size="small" closeIcon>
        <Header icon="cart" content="Checkout" />
        <Modal.Content>
          {(selectedDate && (
            <h3>
              Book your stay & hunt on{' '}
              {selectedDate.format('dddd, MMMM Do YYYY')}?
            </h3>
          )) ||
            'No Date Selected.  Please select a date to continue.'}
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={this.handleClose}>
            Not Yet
          </Button>
          <Checkout />
        </Modal.Actions>
      </Modal>
    );
  }
}

CheckoutModal.propTypes = {
  selectedDate: PropTypes.instanceOf(moment),
  open: PropTypes.bool.isRequired,
};
